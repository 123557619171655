<template>
  <div>
    <!-- Filters -->
    <products-list-filters
      :category-filter.sync="categoryFilter"
      :region-filter.sync="regionFilter"
      :type-filter.sync="typeFilter"
      :category-options="productCategory"
      :region-options="regionOptions"
      :type-options="productType"
      :business-options="productBussiess"
      :business-filter.sync="bussinessFilter"
      :role-options="productRole"
      :role-filter.sync="roleFilter"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('product_create')"
                variant="primary"
                :to="{ name: 'add-publication' }"
              >
                <span class="text-nowrap">Add publication</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchProducts"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Title -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                :to="
                  data.item.status === 'approved' && data.item.is_on_edit
                    ? { name: 'onedit-publication', params: { id: data.item.id } }
                    : { name: 'detail-publication', params: { id: data.item.id } }
                "
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.title }}
              </b-link>
            </b-media>
          </template>
          <!-- Column: Date time -->
          <template #cell(dateTime)="data">
            <span class="text-capitalize">
              {{  data.item.metadata && data.item.metadata.metadata2 ? formatDate(data.item.metadata.metadata2.startDatetime) : '' }}
            </span>
          </template>
          <!-- Column: Region -->
          <template #cell(region_id)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{
                data.item.region_id.toUpperCase()
              }}</span>
            </div>
          </template>
          <!-- Column: Price -->
          <template #cell(price)="data">
            <b-badge
              pill
              class="text-capitalize"
            >
              {{ data.item.price ? data.item.price.from_price : "None" }}
            </b-badge>
          </template>
          <!-- Column: Type -->
          <template #cell(type)="data">
            <b-badge
              pill
              :variant="`light-${resolveTypeProduct(data.item.type)}`"
              class="text-capitalize"
            >
              {{ data.item.type }}
            </b-badge>
          </template>
          <!-- Column: Role -->
          <template #cell(role)="data">
            <div v-if="data.item.product_roles.length">
              <b-badge
                v-for="role in data.item.product_roles"
                :key="role.id"
                pill
                :variant="`light-${getRandomBsVariant()}`"
                class="text-capitalize"
              >
                {{ role.name }}
              </b-badge>
            </div>

          </template>
          <!-- Column: Bussiness Challenge -->
          <template #cell(bussinessChallenges)="data">
            <div v-if="data.item.product_bussiness_challenges.length">
              <b-badge
                v-for="bussiness in data.item.product_bussiness_challenges"
                :key="bussiness.id"
                pill
                :variant="`light-${getRandomBsVariant()}`"
                class="text-capitalize"
              >
                {{ bussiness.name }}
              </b-badge>
            </div>

          </template>
          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveStatusProduct(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status }}
            </b-badge>
            <b-badge
              v-if="data.item.is_on_edit"
              pill
              :variant="`light-${resolveStatusProduct('pending')}`"
              class="text-capitalize"
            >
              Edited
            </b-badge>
          </template>
          <!-- Column: CreatedAt -->
          <template #cell(created_at)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.created_at) }}
            </span>
          </template>
          <!-- Column: CreateBy -->
          <template #cell(created_by)="data">
            <span class="text-capitalize"> Comming Soon </span>
          </template>
          <!-- Column: CreateBy -->
          <template #cell(approved_by)="data">
            <span class="text-capitalize"> Comming Soon </span>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-link
              v-if="$can('product_delete')"
              @click="deleteProduct(data.item.id)"
            >
              Delete
            </b-link>
            <b-link
              :href="`${storeUrl.replace(
                'region-code',
                data.item.region_id
              )}/offering-preview/${data.item.slug}/${data.item.region_id}`"
            >
              Preview
            </b-link>
            <b-link
              v-if="$can('product_create')"
              :to="{ name: 'duplicate-publication', params: { id: data.item.id } }"
            >
              Duplicate
            </b-link>
            <div v-if="data.item.status === 'draft' && $can('product_approve')">
              <b-link
                @click="approvedProduct(data.item.id)"
              >
                Approve
              </b-link>
            </div>
            <div
              v-if="data.item.status === 'approved' && $can('product_deactivate')"
            >
              <b-link @click="rejectProduct(data.item.id)">
                Reject
              </b-link>
            </div>
            <div v-if="data.item.status === 'reject' && $can('product_approve')">
              <b-link @click="RotateProduct(data.item.id)">
                Rotate
              </b-link>
            </div>
            <div
              v-if="
                data.item.status === 'approved' &&
                  data.item.is_on_edit &&
                  $can('product_approve')
              "
            >
              <b-link @click="reConfirmProduct(data.item.id)">
                Reconfirm
              </b-link>
            </div>
            <b-link
              v-if="$can('tracking_read')"
              @click="showModalTracking(data.item.id)"
            >
              Tracking
            </b-link>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <tracking-status
      :is-show-modal-tracking.sync="isShowModalTracking"
      :target="target"
      :actor="actor"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import ProductsListFilters from '@/components/publicationManager/ProductsListFilters.vue'
// eslint-disable-next-line import/no-cycle
import useProductsList from '@/views/offeringManager/publicationManager/productList'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { mapGetters } from 'vuex'
import { formatDate, getRandomBsVariant } from '../../../utils/utils'
import TrackingStatus from '../../modal/TrackingStatus.vue'
import * as endpointConfig from '../../../utils/endpoints'

export default {
  components: {
    BOverlay,
    TrackingStatus,
    ProductsListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    vSelect,
  },
  computed: {
    ...mapGetters('app', ['regionOptions']),
    ...mapGetters('product', ['productType']),
    ...mapGetters('product', ['productCategory']),
    ...mapGetters('bussiness', ['productBussiess']),
    ...mapGetters('role', ['productRole']),
  },
  async created() {
    await store.dispatch('product/fetchCategories')
    await store.dispatch('bussiness/fetchBussiness')
    await store.dispatch('role/fetchRole')
  },
  methods: {
    showModalTracking(id) {
      this.target = `storeConnection:asset:${id}`
      this.actor = ''
      this.isShowModalTracking = true
    },
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 600)
    },
    deleteProduct(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this product.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('product/deleteProduct', { id }).then(() => {
              this.refreshTable()
            })
          }
        })
    },
    approvedProduct(id) {
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to approved this product.',
          {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            store
              .dispatch('product/updateStatusProduct', { id, confirm: true })
              .then(() => {
                this.refreshTable()
              })
          }
        })
    },
    rejectProduct(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to reject this product.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store
              .dispatch('product/updateStatusProduct', { id, confirm: false })
              .then(() => {
                this.refreshTable()
              })
          }
        })
    },
    reConfirmProduct(id) {
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to reconfirm this product.',
          {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            store.dispatch('product/confirmOnEdit', { id }).then(() => {
              this.refreshTable()
            })
          }
        })
    },
    RotateProduct(id) {
      this.$bvModal
        .msgBoxConfirm(
          'Please confirm that you want to rotate this product to draft.',
          {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            store
              .dispatch('product/updateStatusProduct', { id, confirm: true })
              .then(() => {
                this.refreshTable()
              })
          }
        })
    },
  },

  setup() {
    const target = ref('')
    const actor = ref('')
    const isAddNewProductSidebarActive = ref(false)
    const isShowModalTracking = ref(false)
    const vendorOptions = [
      { label: 'Vendor 5', value: 'Vendor 5' },
      { label: 'Vendor 4', value: 'Vendor 4' },
      { label: 'Vendor 3', value: 'Vendor 3' },
      { label: 'Vendor 2', value: 'Vendor 2' },
      { label: 'Vendor 1', value: 'Vendor 1' },
    ]

    const outOfStockOptions = [
      { label: 'True', value: true },
      { label: 'False', value: false },
    ]

    const activeOptions = [
      { label: 'Active', value: true },
      { label: 'Deactive', value: false },
    ]

    const {
      timeout,
      showTable,
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserStatusVariant,
      resolveStatusProduct,
      resolveTypeProduct,
      // Extra Filters
      categoryFilter,
      regionFilter,
      typeFilter,
      bussinessFilter,
      roleFilter,
    } = useProductsList()
    return {
      timeout,
      showTable,
      target,
      actor,
      isShowModalTracking,
      formatDate,
      getRandomBsVariant,
      // Sidebar
      isAddNewProductSidebarActive,
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      regionFilter,
      // Filter
      avatarText,

      // UI
      resolveStatusProduct,
      resolveUserStatusVariant,
      resolveTypeProduct,
      vendorOptions,
      outOfStockOptions,
      activeOptions,

      // Extra Filters
      categoryFilter,
      typeFilter,
      bussinessFilter,
      roleFilter,
      storeUrl: endpointConfig.STORE_PROD_URL
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
